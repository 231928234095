<template lang="pug">
  .notification
    TopBar(
      :reference-items-loading="referenceItemsLoading"
      :car-classes-loading="carClassesLoading"
      :ota-list="translatedOtaList"
      :reference-items="referenceItems"
      :car-classes="carClasses"
      :statuses="readStatuses"
      :objects="objects"
      :filters="filters"
      :disabled="disabledTopbarActionButtons"
      @change-filters="applyFilters"
      @read-notifications="updateNotificationsReadStatus(true)"
      @unread-notifications="updateNotificationsReadStatus(false)"
      @delete-notifications="handleDelete"
    )
    .notification-wrapper
      AppOverlayLoader(:state="notificationsLoading")
      NotificationsTable(
        :notifications="notifications"
        :selected-notifications-ids="selectedNotificationsIds[pagination.current_page]"
        :sort-by="sorting"
        :filters="filters"
        @select-notifications="handleSelect"
        @sort-change="handleSorting"
      )
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        :auto-scroll-on-pagination="{ target: '.table-wrapper' }"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // store modules
  import inventoryGroupsModule from "@/config/store/matching/inventory_group"
  import shopsModule from "@/config/store/matching/shop"
  import carClassesModule from "@/config/store/matching/car_class"
  import notificationsModule from "@/config/store/notifications"

  // mixins
  import withWebSocket from "@/mixins/withWebSocket"
  import withStoreModule from "@/mixins/withStoreModule"

  // misc
  import { appDebounce } from "@/helpers/common"
  import { isSharedInventoryEnabled } from "@/helpers/organization"
  import { mapGetters } from "vuex"
  import { includes, isEmpty, isEqual, transform, values } from "lodash-es"

  const notificationsMixin = withStoreModule(notificationsModule, {
    name: "notifications",
    readers: {
      notifications: "items",
      notificationsLoading: "loading",
      pagination: "pagination",
      filters: "filters",
      readStatuses: "readStatuses",
      objects: "objects",
      sorting: "sorting"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      setFilters: "SET_FILTERS",
      setSorting: "SET_SORTING"
    },
    actions: {
      fetchNotifications: "FETCH_POST_ITEMS",
      readNotifications: "SWITCH_READ_ITEMS",
      deleteNotifications: "DELETE_ITEMS"
    }
  })

  const inventoryGroupsMixin = withStoreModule(inventoryGroupsModule, {
    name: "inventoryGroups",
    readers: { inventoryGroups: "items", inventoryGroupsLoading: "loading" },
    actions: {
      fetchInventoryGroups: "FETCH_ITEMS"
    }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    name: "shops",
    readers: { shops: "items", shopsLoading: "loading" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const carClassesMixin = withStoreModule(carClassesModule, {
    name: "carClasses",
    readers: { carClasses: "items", carClassesLoading: "loading" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  export default {
    components: {
      TopBar: () => import("./TopBar"),
      NotificationsTable: () => import("./NotificationsTable"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppPagination: () => import("@/components/elements/AppPagination")
    },

    mixins: [notificationsMixin, shopsMixin, inventoryGroupsMixin, withWebSocket, carClassesMixin],

    data() {
      return {
        selectedNotificationsIds: {}
      }
    },

    mounted() {
      if (this.isSharedInventoryEnabled) {
        this.fetchInventoryGroups()
      } else {
        this.fetchShops({ pagination: { _disabled: true } })
      }
      this.fetchCarClasses({ pagination: { _disabled: true } })
      this.fetchNotifications()

      this.debouncedFetchNotifications = appDebounce(() => {
        this.fetchNotifications().then(this.resetSelectedNotifications)
      })
    },

    computed: {
      isSharedInventoryEnabled,

      ...mapGetters(["translatedOtaList"]),

      selectedNotificationsIdsCurrentPage() {
        return this.selectedNotificationsIds[this.pagination.current_page]
      },

      disabledTopbarActionButtons() {
        return isEmpty(this.selectedNotificationsIdsCurrentPage)
      },

      referenceItemsLoading() {
        return this.isSharedInventoryEnabled ? this.inventoryGroupsLoading : this.shopsLoading
      },

      referenceItems() {
        return this.isSharedInventoryEnabled ? this.inventoryGroups : this.shops
      },

      readStatuses() {
        return [
          {
            title: this.$t("notifications.filters.read"),
            value: "read"
          },
          {
            title: this.$t("notifications.filters.unread"),
            value: "unread"
          }
        ]
      },

      objects() {
        const grouped = transform(
          this.$i18n.t("notifications.table.object"),
          (acc, title, code) => {
            if (code !== "default" && !includes(code, "000")) {
              acc[title] ||= { title: title, value: [] }
              acc[title]["value"].push(code)
            }
            return acc
          },
          {}
        )
        return values(grouped)
      }
    },

    methods: {
      changePagination(paginationData) {
        if (this.pagination.per_page !== paginationData.per_page) {
          this.resetSelectedNotifications()
        }

        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchNotifications()
      },

      applyFilters(filters, debounced = true) {
        this.setFilters(filters)
        this.setPagination({ ...this.pagination, current_page: 1 })

        if (debounced) {
          this.debouncedFetchNotifications()
        } else {
          this.fetchNotifications()
        }
      },

      handleSelect(selectedIds) {
        this.selectedNotificationsIds = {
          ...this.selectedNotificationsIds,
          [this.pagination.current_page]: selectedIds
        }
      },

      updateNotificationsReadStatus(status) {
        this.readNotifications({
          ids: this.selectedNotificationsIdsCurrentPage,
          read: status
        }).then(() => {
          this.resetSelectedNotificationsOnCurrentPage()
        })
      },

      handleDelete() {
        const currentPageValue =
          this.notifications.length > this.selectedNotificationsIdsCurrentPage.length ? this.pagination.current_page : 1

        this.deleteNotifications({
          ids: this.selectedNotificationsIdsCurrentPage
        })
          .then(() => {
            this.resetSelectedNotifications()
            this.changePagination({ current_page: currentPageValue })
          })
          .then(() => {
            this.fetchNotifications()
          })
      },

      resetSelectedNotifications() {
        this.selectedNotificationsIds = {}
      },

      resetSelectedNotificationsOnCurrentPage() {
        this.selectedNotificationsIds[this.pagination.current_page] = []
      },

      handleSorting([sortingData]) {
        if (isEqual(sortingData, this.sorting)) return

        this.setSorting(sortingData)
        this.fetchNotifications().then(this.resetSelectedNotifications)
      }
    }
  }
</script>

<style lang="sass" scoped>
  .notification-wrapper
    position: relative
</style>
